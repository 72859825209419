import { Calendar as AntdCalendar, CalendarProps } from "antd";
import { Dayjs } from "dayjs";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";
import moment, { Moment } from "moment";

export const Calendar = () => {
  const onPanelChange = (
    value: Moment,
    mode: CalendarProps<Moment>["mode"]
  ) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const { height } = useRecoilValue(windowSize);

  const listData = [
    {
      date: new Date().getTime(),
      type: "warning",
      content: "This is warning event.",
    },
  ];

  //   const dateCellRender = (value: Moment) => {
  //     //const listData = getListData(value);
  //     const newData: any = listData
  //       .filter((a) => new Date(a.date).getMonth() === value.month())
  //       .map(
  //         <ul className="events">
  //           {listData.map((item) => (
  //             <li key={item.content}>
  //               <Badge
  //                 status={item.type as BadgeProps["status"]}
  //                 text={item.content}
  //               />
  //             </li>
  //           ))}
  //         </ul>
  //       );
  //   };

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          width: "100vw",
          //height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AntdCalendar
          onPanelChange={onPanelChange}
          dateCellRender={(date) => {
            return listData
              .filter(
                (data) =>
                  moment(data.date).format("YYYY-MM-DD").toString() ===
                  date.format("YYYY-MM-DD").toString()
              )
              .map((data) => {
                return (
                  <div>
                    {date.format("YYYY-MM-DD").toString()}
                    <div>
                      {moment(data.date).format("YYYY-MM-DD").toString()}
                    </div>
                  </div>
                );
              });
          }}
        />
      </div>
    </div>
  );
};
