import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { nanoid } from "nanoid";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { FinanceEntryType } from "../typeDefs";

const categories: { categories: any; options: any } = {
  categories: {
    Revenue: [
      "Sales Revenue",
      "Service Revenue",
      "Interest Income",
      "Dividend Income",
      "Rental Income",
    ],
    Expenses: [
      "Cost of Goods Sold",
      "Salaries and Wages",
      "Rent Expense",
      "Utilities Expense",
      "Depreciation Expense",
      "Interest Expense",
      "Insurance Expense",
      "Advertising Expense",
      "Subscriptions",
    ],
    Assets: [
      "Cash and Cash Equivalents",
      "Accounts Receivable",
      "Inventory",
      "Prepaid Expenses",
      "Property, Plant, and Equipment",
      "Intangible Assets",
      "Investments",
      "Office Fit-Out",
    ],
    Liabilities: [
      "Accounts Payable",
      "Short-term Debt",
      "Long-term Debt",
      "Accrued Liabilities",
      "Deferred Revenue",
      "Notes Payable",
    ],
    Equity: [
      "Common Stock",
      "Preferred Stock",
      "Retained Earnings",
      "Additional Paid-in Capital",
      "Treasury Stock",
    ],
  },
  options: {
    "Income Statement": [
      "Sales Revenue",
      "Service Revenue",
      "Cost of Goods Sold",
      "Salaries and Wages",
      "Rent Expense",
      "Utilities Expense",
      "Depreciation Expense",
      "Interest Income",
      "Interest Expense",
      "Advertising Expense",
      "Net Income",
    ],
    "Balance Sheet": [
      "Cash and Cash Equivalents",
      "Accounts Receivable",
      "Inventory",
      "Prepaid Expenses",
      "Property, Plant, and Equipment",
      "Intangible Assets",
      "Investments",
      "Accounts Payable",
      "Short-term Debt",
      "Long-term Debt",
      "Accrued Liabilities",
      "Deferred Revenue",
      "Notes Payable",
      "Common Stock",
      "Preferred Stock",
      "Retained Earnings",
      "Additional Paid-in Capital",
      "Treasury Stock",
    ],
    "Cash Flow Statement": [
      "Net Income",
      "Depreciation Expense",
      "Accounts Receivable",
      "Inventory",
      "Accounts Payable",
      "Accrued Liabilities",
      "Cash from Operating Activities",
      "Cash from Investing Activities",
      "Cash from Financing Activities",
    ],
    "Statement of Changes in Equity": [
      "Common Stock",
      "Preferred Stock",
      "Retained Earnings",
      "Additional Paid-in Capital",
      "Treasury Stock",
      "Net Income",
      "Dividends",
    ],
  },
};

export const Finances = () => {
  const nav = useNavigate();
  const [activeCategory, setActiveCategory] = useState<string>("Revenue");
  const [imagePath, setImagePath] = useState<string | null>(null);
  const [gstSearch, setGstSearch] = useState<string>("");
  const [isGstRegistered, setIsGstRegistered] = useState<boolean>(true);
  const [isGstIncluded, setIsGstIncluded] = useState<boolean>(true);
  const [entryDate, setEntryDate] = useState<number>(Date.now());
  const [vendors, setVendors] = useState<
    Array<{ vendor: string; id: string; gstNumber: string }>
  >([]);

  const [entryForm] = Form.useForm();

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "vendors"),
      (snapDoc) => {
        setVendors(
          snapDoc.docs.map(
            (doc) =>
              ({ ...doc.data(), id: doc.id } as {
                vendor: string;
                id: string;
                gstNumber: string;
              })
          )
        );
      },
      (err) => {
        console.error(err);
      }
    );
    return unsub;
  }, []);

  const handleAdEntry = () => {
    entryForm
      .validateFields()
      .then((values) => {
        console.log({ values });
        const newId = nanoid();
        const servicesDoc = doc(db, `/finances/${newId}`);
        setDoc(servicesDoc, {
          ...values,
          gstNumber: values.gstNumber ? values.gstNumber : null,
          date: entryDate,
          id: newId,
          image: imagePath,
          isDeleted: false,
          receipt: imagePath ? true : false,
        } as FinanceEntryType)
          .then(() => message.success("Saved new service."))
          .catch((err) => console.error(err));

        return values;
      })
      .then((values) => {
        if (!vendors.map((item) => item.vendor).includes(values.vendor)) {
          const vendorId = nanoid();
          const vendorDoc = doc(db, `/vendors/${vendorId}`);
          setDoc(vendorDoc, {
            id: vendorId,
            vendor: values.vendor,
            gstNumber: values.gstNumber,
          })
            .then(() => message.success("Vendors saved"))
            .catch((err) => console.error(err));
        }
      })
      .then(() => {
        entryForm.resetFields();
        setGstSearch("");
      })
      .catch((err) => {
        message.error(err.message);
        console.error(err);
      });
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgb(30,30,30)",
        color: "rgb(200,200,200)",
      }}
    >
      <div
        style={{
          width: "100vw",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            color: "rgb(200,200,200)",
            fontFamily: "Oxanium",
          }}
        >
          Add Financial Entry
        </h1>
        <Button
          style={{ marginBottom: "1rem", color: "rgb(200,200,200)" }}
          type="text"
          onClick={() => nav("/finance-reports")}
        >
          Reports
        </Button>
        <Form
          style={{ width: 300 }}
          form={entryForm}
          initialValues={{ gstNumber: "" }}
        >
          <Form.Item>
            <DatePicker
              onChange={(e) => {
                if (!e) return;
                setEntryDate(e?.toDate().getTime());
              }}
            />
          </Form.Item>
          <Form.Item name="description">
            <Input placeholder="Description" />
          </Form.Item>
          <Form.Item name="gstNumber">
            <Input
              placeholder="GST Number"
              onChange={(e) => setGstSearch(e.target.value)}
            />
          </Form.Item>
          {gstSearch.length > 2 && vendors.length > 0 && (
            <div>
              {vendors
                .filter((item) => item.gstNumber?.includes(gstSearch))
                .map((item, idx) => (
                  <p
                    style={{ color: "rgb(200,200,200)" }}
                    onClick={() =>
                      entryForm.setFieldsValue({
                        gstNumber: item.gstNumber,
                        vendor: item.vendor,
                      })
                    }
                    key={idx}
                  >
                    {item.vendor}
                  </p>
                ))}
            </div>
          )}
          <Form.Item name="vendor">
            <Input placeholder="Other Party" />
          </Form.Item>

          <Form.Item name={"amount"}>
            <InputNumber placeholder="Amount" />
          </Form.Item>
          <Form.Item name="category">
            <Select
              placeholder="Category"
              onChange={(e) => setActiveCategory(e)}
            >
              {Object.keys(categories.categories).map((key, idx) => {
                return (
                  <Select.Option key={idx} value={key}>
                    {key}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="subCategory">
            <Select placeholder="Sub Category">
              {categories.categories[activeCategory].map(
                (key: string, idx: number) => {
                  return (
                    <Select.Option key={idx} value={key}>
                      {key}
                    </Select.Option>
                  );
                }
              )}
            </Select>
          </Form.Item>

          <Form.Item>
            <Checkbox
              style={{ color: "rgb(200,200,200)" }}
              onChange={() => setIsGstIncluded(!isGstIncluded)}
              checked={isGstIncluded}
            >
              Includes GST
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              style={{ color: "rgb(200,200,200)" }}
              onChange={() => setIsGstRegistered(!isGstRegistered)}
              checked={isGstRegistered}
            >
              GST Registered
            </Checkbox>
          </Form.Item>
          <Upload
            beforeUpload={(upload) => {
              const storageRef = ref(storage, `receipts/${upload.name}`);
              if (upload.type === "image/jpeg" || upload.type === "image/png") {
                uploadBytes(storageRef, upload)
                  .then((snapshot) => {
                    message.success("Uploaded file!");
                  })
                  .then(() => getDownloadURL(storageRef))
                  .then((url) => {
                    setImagePath(url);
                  })
                  .catch((err) => console.error(err));
              }
            }}
          >
            <Button>Select File</Button>
          </Upload>
          <Button
            style={{ marginTop: ".25rem" }}
            onClick={() => {
              handleAdEntry();
            }}
          >
            Add
          </Button>
        </Form>
      </div>
    </div>
  );
};
