import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as logo from "../Images/SCRYPTWORX STUDIOS Slim.png";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

(window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;

type imageType = { image: string; width: string };
type textType = { text: string; style: string };

export const Invoices = () => {
  const [invoiceForm] = Form.useForm();
  const [productList, setProductList] = useState<Array<string>>([]);
  const [dueDate, setDueDate] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [isGST, setIsGST] = useState(true);

  const [name, setName] = useState<string>("List: ");
  const [docContent, setDocContent] = useState<Array<any>>([
    { text: name, style: "header" },
    { text: `Generated: ${new Date().toISOString()}`, style: "subheader" },
  ]);
  const job = "8 Te Hurunui";

  const blobToDataURL = (blob: Blob, callback: any) => {
    var a = new FileReader();
    a.onload = function (e) {
      callback(e?.target?.result);
    };
    a.readAsDataURL(blob);
  };

  let url =
    "https://firebasestorage.googleapis.com/v0/b/scryptworxstudios.appspot.com/o/images%2FSWS%20LOGO%20-%20grey.png?alt=media&token=db343482-0fdf-4647-89a1-8d188c9fa4ce";

  const createInvoice = () => {
    invoiceForm
      .validateFields()
      .then((values) => {
        console.log({ values, productList });
        let dateNow = new Date();
        let invoiceNumber = `${dateNow.getFullYear()}${
          dateNow.getMonth() + 1
        }${dateNow.getDate()}${Math.round(Math.random() * 10)}${Math.round(
          Math.random() * 10
        )}`;

        let totalCost = 0;

        let newList = [];
        if (productList.includes("VPWDBI")) {
          totalCost = values.customValue;

          // products.filter((product) =>
          //   product.code.includes("VPWDBI")
          // )[0].name

          newList = [
            [
              {
                text: values.customItem,
              },
              { text: values["VPWDBI"] },
              {
                text: `$${values.customValue} ${isGST ? "incl GST" : ""}`,
              },
            ],
          ];
        } else {
          productList.forEach((product) => {
            totalCost =
              totalCost +
              products.filter((productItem) => productItem.code === product)[0]
                .price *
                values[product];
          });

          newList = productList.map((item, idx) => {
            return [
              {
                text: products.filter((product) =>
                  product.code.includes(item)
                )[0].name,
              },
              { text: values[item] },
              {
                text: `$${
                  products.filter((product) => product.code.includes(item))[0]
                    .price
                } ${isGST ? "incl GST" : ""}`,
              },
            ];
          });
        }

        let productsConverted = [["Item", "Quantity", "Cost"], ...newList];

        let content = [
          //{image: }
          { text: "SCRYPTWORX STUDIOS LIMITED", style: "header" },
          { text: `INVOICE ${invoiceNumber.split("20")[1]}`, style: "header" }, //
          "\n",
          { text: `Customer: ${values.client}`, style: "subheader" },
          {
            text: `Customer Reference: ${values.reference}`,
            style: "subheader",
          },
          "\n",
          {
            layout: "lightHorizontalLines", // optional
            table: {
              headerRows: 1,
              widths: [200, "*", 100],
              body: [
                [
                  {
                    text: `Issued: ${issueDate}`,
                    style: "small",
                  },
                  {
                    text: `Due: ${dueDate}`,
                    style: "small",
                  },
                ],
              ],
            },
          },
          {
            text: `${isGST ? "GST: 136-354-817" : ""}`,
            style: "small",
          },
          "\n",
          {
            layout: "lightHorizontalLines",
            table: {
              headerRows: 1,
              widths: [200, "*", 100],
              body: productsConverted,
              //  [
              //   // [{ text: "House Photography", bold: true }, "Val 2", "Val 3"],
              //   productsConverted
              // ],
            },
          },
          "\n",
          "\n",
          "\n",
          "\n",
          {
            layout: "lightHorizontalLines", // optional
            table: {
              headerRows: 1,
              widths: [200, "*", 100],
              body: [
                ["", "", { text: "Total", style: "bold" }],
                ["", "", `$${totalCost} ${isGST ? "incl GST" : ""}`],
              ],
              //  [
              //   // [{ text: "House Photography", bold: true }, "Val 2", "Val 3"],
              //   productsConverted
              // ],
            },
          },
          "\n",
          "\n",
          "\n",
          {
            layout: "lightHorizontalLines", // optional
            table: {
              headerRows: 1,
              widths: [250, 200, "*"],
              body: [
                [
                  {
                    text: `Payment details`,
                    style: "small",
                  },
                  {
                    text: ``,
                    style: "small",
                  },
                ],
                [
                  {
                    text: "Account name: SCRYPTWORX STUDIOS LIMITED.",
                    style: "small",
                  },
                  {
                    text: `Account number: 12-3616-0018990-00`,
                    style: "small",
                  },
                ],
              ],
            },
          },
        ];

        let finalProducts = [];
        if (productList.includes("VPWDBI")) {
          finalProducts = [
            {
              name: values.customItem,
              price: values.customValue,
              code: "VPWDBI",
            },
          ];
        } else {
          finalProducts = productList.map(
            (product) => products.filter((pro) => pro.code === product)[0]
          );
        }

        setDoc(doc(db, `invoices/${invoiceNumber}`), {
          client: values.client,
          clientReference: values.reference,
          products: finalProducts,
          dueDate: dueDate,
          issueDate: new Date().getTime(),
          paid: false,
        });
        console.log(JSON.stringify(newList));
        return content;
      })
      .then((content) => {
        var docDefinition: TDocumentDefinitions = {
          pageSize: "A4",
          pageMargins: [40, 60, 40, 60],
          content: content as Content,
          info: {
            title: "SCRYPTWORX STUDIOS Invoice",
            author: "scryptworxstudios.com",
            subject: "Invoice",
            keywords: "Invoice",
          },
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              color: "#1e90ff",
            },
            subheader: {
              fontSize: 12,
              bold: true,
            },
            quote: {
              italics: true,
            },
            small: {
              fontSize: 10,
            },
          },
        };
        pdfMake.createPdf(docDefinition).open();
      })
      .catch((err: any) => console.error(err));
  };

  const products = [
    { name: "Real Estate Photography - Premium", price: 500, code: "REPP" },
    { name: "Real Estate Photography - Standard", price: 300, code: "REPS" },
    { name: "Real Estate Videography - Standard", price: 200, code: "REVS" },
    { name: "Real Estate Videography - Premium", price: 300, code: "REVP" },
    {
      name: "Drone photography and/or videography addon",
      price: 100,
      code: "REDVP",
    },
    {
      name: "Video production, web development, and business intelligence services",
      price: 0,
      code: "VPWDBI",
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgb(30,30,30)",
      }}
    >
      <div
        style={{
          width: "100vw",
          //height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(40,40,40)",
            maxWidth: "700px",
            width: "90vw",
            alignSelf: "center",
            padding: "2rem",
            boxShadow: "0 5px 10px rgb(20,20,20)",
            borderRadius: ".25rem",
          }}
        >
          <Image
            preview={false}
            width={200}
            src="https://firebasestorage.googleapis.com/v0/b/scryptworxstudios.appspot.com/o/images%2FSWS%20LOGO%20-%20grey.png?alt=media&token=db343482-0fdf-4647-89a1-8d188c9fa4ce"
          />
          <Form form={invoiceForm}>
            <Form.Item name="issueDate">
              <DatePicker
                onChange={(date) => {
                  if (!date) return;
                  setIssueDate(date?.toDate().toLocaleString());
                }}
                placeholder="Issue Date"
              />
            </Form.Item>
            <Form.Item name="date">
              <DatePicker
                onChange={(date) => {
                  if (!date) return;
                  setDueDate(date?.toDate().toLocaleString());
                }}
                placeholder="Due Date"
              />
            </Form.Item>
            <Form.Item name="client">
              <Input placeholder="Client" />
            </Form.Item>
            <Form.Item name="reference">
              <Input placeholder="Reference" />
            </Form.Item>
            <Form.Item name="item">
              <Select
                mode="multiple"
                onChange={(value: any) => {
                  setProductList((prev) => [...value]);
                  console.log(productList);
                }}
              >
                {products.map((product, idx) => {
                  return (
                    <Select.Option key={idx} value={product.code}>
                      {product.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {productList.includes("VPWDBI") && (
              <>
                <Form.Item name="customValue">
                  <Input placeholder="Custom billable amount including GST" />
                </Form.Item>{" "}
                <Form.Item name="customItem">
                  <Input placeholder="Custom billable item" />
                </Form.Item>{" "}
              </>
            )}

            {productList &&
              productList.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{`Code: ${item}`}</div>
                    <div>
                      {
                        products.filter((product) =>
                          product.code.includes(item)
                        )[0].name
                      }
                    </div>
                    <div>
                      {`$
                   ${
                     products.filter((product) =>
                       product.code.includes(item)
                     )[0].price
                   }`}
                    </div>
                    <Form.Item name={item}>
                      <InputNumber placeholder="QTY" />
                    </Form.Item>
                  </div>
                );
              })}
          </Form>
          <Button onClick={() => (isGST ? setIsGST(false) : setIsGST(true))}>
            {isGST ? "Invoice includes GST" : "No GST on this invoice"}
          </Button>
          <Button
            onClick={() => {
              createInvoice();
            }}
          >
            Create Invoice
          </Button>
        </div>
      </div>
    </div>
  );
};
