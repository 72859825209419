import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  message,
} from "antd";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { nanoid } from "nanoid";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  CheckSquareOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Moment } from "moment";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";
(window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;

type TaskType = {
  isComplete: boolean;
  isDeleted: boolean;
  taskOrg: string;
  startTime: number;
  endTime?: number;
  description?: string;
  id: string;
  taskDetail?: string;
  taskNote?: string;
};

type ClientType = {
  clientName: string;
  id: string;
  rate: string;
};

type CategoryType = {
  category: string;
  id: string;
  client: string;
};
export const Tasks = () => {
  const [taskForm] = Form.useForm();
  const [descriptionForm] = Form.useForm();
  const [printForm] = Form.useForm();
  const { width } = useRecoilValue(windowSize);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [tasks, setTasks] = useState<Array<TaskType>>([]);
  const [clients, setClients] = useState<Array<ClientType>>([]);
  const [categories, setCategories] = useState<Array<CategoryType>>([]);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "tasks"), (snapshot) => {
      setTasks([]);
      snapshot.forEach((snapDoc) => {
        setTasks((prev) => {
          return [...prev, { ...snapDoc.data(), id: snapDoc.id } as TaskType];
        });
      });
    });

    return unsub;
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "clients"), (snapshot) => {
      setClients([]);
      snapshot.forEach((snapDoc) => {
        setClients((prev) => {
          return [...prev, { ...snapDoc.data(), id: snapDoc.id } as ClientType];
        });
      });
    });

    return unsub;
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "categories"), (snapshot) => {
      setCategories([]);
      snapshot.forEach((snapDoc) => {
        setCategories((prev) => {
          return [
            ...prev,
            { ...snapDoc.data(), id: snapDoc.id } as CategoryType,
          ];
        });
      });
    });

    return unsub;
  }, []);

  const openTask = () => {
    taskForm
      .validateFields()
      .then((values) => {
        const taskId = nanoid();
        setDoc(doc(db, `tasks/${taskId}`), {
          taskOrg: values.taskOrg,
          startTime: new Date().getTime(),
          isDeleted: false,
          isComplete: false,
        })
          .then(() => {
            message.success("Task started");
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => console.error(err));
  };

  const closeTask = (id: string) => {
    descriptionForm
      .validateFields()
      .then((values) => {
        updateDoc(doc(db, `tasks/${id}`), {
          isComplete: true,
          endTime: new Date().getTime(),
          description: values.des,
          taskDetail: values.taskDetail,
        } as TaskType).then(() => {
          message.success("Task completed.");
          setActiveId(null);
        });
      })
      .catch((err) => {
        console.error(err);
        message.error("Failed to update");
      });
  };

  const printRecord = (
    content: Content[],
    dates: [number, number],
    client: string,
    amount: number,
    rate: number
  ) => {
    // const content: Content = [{ text: "Test" }];
    var docDefinition: TDocumentDefinitions = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
        { text: "SCRYPTWORX STUDIOS INVOICE \n \n", style: "header" },
        {
          text: `Work period: ${new Date(
            dates[0]
          ).toLocaleString()} to ${new Date(dates[1]).toLocaleString()} \n`,
        },
        { text: `Client: ${client} \n \n` },
        {
          text: `GST: 136-354-817`,
          style: "small",
          bold: true,
        },
        {
          text: `Reference: ${new Date().getDay()}${new Date().getHours()}${new Date().getMilliseconds()} \n \n`,
          style: "small",
          bold: true,
        },
        ...content,
        {
          text: `\n Total time worked: ${(amount / (1000 * 60 * 60)).toFixed(
            2
          )} hours at rate ${rate} \n \n`,
          marginLeft: 20,
        },
        {
          text: `Cost: $${((amount / (1000 * 60 * 60)) * (100 * rate)).toFixed(
            2
          )} \n`,
          marginLeft: 20,
        },
        {
          text: `GST component: $${(
            (amount / (1000 * 60 * 60)) *
            (100 * rate) *
            0.15
          ).toFixed(2)} \n \n`,
          marginLeft: 20,
        },
        {
          text: `Total billed: $${(
            (amount / (1000 * 60 * 60)) * (100 * rate) +
            (amount / (1000 * 60 * 60)) * (100 * rate) * 0.15
          ).toFixed(2)} \n \n \n`,
          bold: true,
          marginLeft: 20,
        },

        {
          layout: "lightHorizontalLines", // optional
          table: {
            headerRows: 1,
            widths: [250, 200, "*"],
            body: [
              [
                {
                  text: `Payment details`,
                  style: "small",
                },
                {
                  text: ``,
                  style: "small",
                },
              ],
              [
                {
                  text: "Account name: SCRYPTWORX STUDIOS LIMITED.",
                  style: "small",
                },
                {
                  text: `Account number: 12-3616-0018990-00`,
                  style: "small",
                },
              ],
            ],
          },
        },
      ],
      info: {
        title: "SCRYPTWORX STUDIOS INVOICE",
        author: "scryptworxstudios.com",
        subject: "Invoice for hours",
        keywords: "Invoice",
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          color: "#1e90ff",
        },
        subheader: {
          fontSize: 12,
          bold: true,
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 10,
        },
      },
    };
    pdfMake.createPdf(docDefinition).open();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "rgb(30,30,30)",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "1200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            paddingBottom: "1rem",
            fontSize: "1.5rem",
            color: "rgb(200,200,200)",
            fontFamily: "Oxanium",
          }}
        >
          Tasks
        </div>
        {tasks.filter((task) => !task.isComplete).length < 1 && (
          <div
            style={{
              width: "90%",
              marginBottom: "1rem",
              padding: "1rem",
              border: "1px solid rgb(220,220,220)",
              borderRadius: ".25rem",
            }}
          >
            <div
              style={{
                fontFamily: "oxanium",
                color: "whitesmoke",
                marginBottom: "1rem",
              }}
            >
              New Task
            </div>
            <Form form={taskForm} layout="inline" style={{ width: "100%" }}>
              <Form.Item name={"taskOrg"} style={{ width: "80%" }}>
                <Select>
                  {clients.map((item, idx) => {
                    return (
                      <Select.Option key={idx} value={item.clientName}>
                        {item.clientName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Popconfirm title="Start Task?" onConfirm={() => openTask()}>
                <Button
                  icon={<PlusOutlined style={{ color: "rgb(200,200,200)" }} />}
                />
              </Popconfirm>
            </Form>
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "50vh",
            overflowY: "auto",
            //padding: "1rem",
            // border: "1px solid rgb(20,20,20)",
            borderRadius: ".25rem",
            marginBottom: "1rem",
          }}
        >
          {tasks
            .sort((itemA, itemB) => itemB.startTime - itemA.startTime)
            .map((item, idx) => {
              const dateData = new Date(item.startTime);
              let endData: Date | null = null;

              if (item.endTime) {
                endData = new Date(item.endTime);
              }

              return (
                <div
                  key={idx}
                  style={{
                    color: "rgb(200,200,200)",
                    width: "100%",
                    padding: ".5rem",
                    marginBottom: ".25rem",
                    border: item.isComplete
                      ? "1px solid rgb(30,30,30)"
                      : "1px solid rgba(150,255,150,.6)",
                    borderRadius: ".25rem",
                    fontSize: ".75rem",
                    backgroundColor: "rgb(40,40,40)",
                    boxShadow: "0 5px 10px rgb(20,20,20)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Button
                        type="text"
                        onClick={() => {
                          window.navigator.clipboard.writeText(item.id);
                          message.info("Copied!");
                        }}
                        style={{
                          color: item.isComplete
                            ? "rgb(255,100,100)"
                            : "rgba(150,255,150,.6)",
                          fontWeight: "bold",
                        }}
                      >
                        {item.taskOrg}
                      </Button>

                      {item.taskDetail && (
                        <div style={{ fontWeight: "bold" }}>
                          {item.taskDetail}
                        </div>
                      )}
                      {item.description && <div>{item.description}</div>}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: width > 800 ? "row" : "column",
                        width: "50%",
                        maxWidth: "360px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{`${dateData.toLocaleDateString()} ${dateData.toLocaleTimeString()}`}</div>
                      {endData && (
                        <>
                          <div>{`${endData.toLocaleDateString()} ${endData.toLocaleTimeString()}`}</div>
                          <div>{`${(
                            (endData.getTime() - dateData.getTime()) /
                            1000 /
                            60 /
                            60
                          ).toPrecision(2)} hrs`}</div>
                        </>
                      )}
                    </div>
                    {!item.isComplete && activeId !== item.id && (
                      <div>
                        <Button
                          type="text"
                          style={{ color: "whitesmoke" }}
                          icon={<CheckSquareOutlined />}
                          onClick={() => setActiveId(item.id)}
                        />
                      </div>
                    )}
                  </div>
                  {activeId === item.id && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Complete this task?</div>

                        <Popconfirm
                          title="Finish task?"
                          onConfirm={() => closeTask(item.id)}
                        >
                          <Button>Yes</Button>
                        </Popconfirm>
                      </div>
                      <Form form={descriptionForm}>
                        <Form.Item
                          name="taskDetail"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select placeholder="Category">
                            {categories
                              .filter((cat) => item.taskOrg === cat.client)
                              .map((cat, idx) => {
                                return (
                                  <Select.Option key={idx} value={cat.category}>
                                    {cat.category}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="des"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input.TextArea placeholder="Description" />
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
            border: "1px solid rgb(30,30,30)",
            backgroundColor: "rgb(40,40,40)",
            borderRadius: ".25rem",
            boxShadow: "0 5px 10px rgb(20,20,20)",
          }}
        >
          <div
            style={{
              fontFamily: "oxanium",
              color: "whitesmoke",
              marginBottom: "1rem",
            }}
          >
            Print Invoice
          </div>
          <Form form={printForm} style={{ width: "100%", maxWidth: "300px" }}>
            <Form.Item name="dates">
              <RangePicker />
            </Form.Item>
            <Form.Item name={"client"} style={{ width: "100%" }}>
              <Select>
                {clients.map((item, idx) => {
                  return (
                    <Select.Option key={idx} value={item.clientName}>
                      {`${item.clientName} - ${item.rate}`}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="rate">
              <InputNumber />
            </Form.Item>
            <Button
              icon={<PrinterOutlined />}
              onClick={() => {
                let amount = 0;
                printForm
                  .validateFields()
                  .then(
                    (values: {
                      dates: Array<Moment>;
                      client: string;
                      rate: number;
                    }) => {
                      const dateStart = values.dates[0]
                        .hour(0)
                        .minute(0)
                        .second(1)
                        .toDate()
                        .getTime();
                      const dateEnd = values.dates[1]
                        .hour(23)
                        .minute(59)
                        .second(59)
                        .toDate()
                        .getTime();
                      const content = tasks
                        .filter((task) => {
                          if (!task.endTime) return false;
                          if (
                            task.startTime > dateStart &&
                            task.endTime < dateEnd &&
                            task.taskOrg === values.client
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                        .map((task) => {
                          const end = task.endTime || 0;

                          amount = amount + (end - task.startTime);

                          return {
                            text: `${(
                              (end - task.startTime) /
                              1000 /
                              60 /
                              60
                            ).toPrecision(2)} hrs - ${task.description}  \n`,
                            style: "small",

                            marginLeft: 20,
                          } as Content;
                          // return {
                          //   text: `${new Date(
                          //     task.startTime
                          //   ).toLocaleString()} --> ${new Date(
                          //     end
                          //   ).toLocaleString()} \n Task description: ${
                          //     task.description
                          //   } \n \n`,
                          //   style: "small",

                          //   marginLeft: 20,
                          // } as Content;
                        });

                      printRecord(
                        content,
                        [dateStart, dateEnd],
                        values.client,
                        amount,
                        values.rate
                      );
                    }
                  );
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};
