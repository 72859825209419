import { Table } from "antd";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { FinanceEntryType } from "../typeDefs";

export const FinanceReports = () => {
  const [data, setData] = useState<Array<FinanceEntryType>>([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "finances"), (snapshot) => {
      setData([]);
      snapshot.forEach((doc) => {
        setData((prev) => [
          ...prev,
          { ...doc.data(), id: doc.id } as FinanceEntryType,
        ]);
      });
    });

    return unsub;
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgb(230,230,230)",
        color: "rgb(200,200,200)",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll",
        }}
      >
        <h1
          style={{
            padding: "1rem",
            color: "rgb(20,20,20)",
            width: "90%",
            textAlign: "left",
            fontFamily: "Oxanium",
            fontSize: "1.5rem",
          }}
        >
          Finance Reports
        </h1>
        <Table
          title={() => (
            <div
              style={{
                fontWeight: "bold",
                //padding: "10px",
                textAlign: "left",
                width: "100%",
                color: "dodgerblue",
                fontSize: "1.5rem",
              }}
            >
              Revenue
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter((item) => item.category === "Revenue")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$(${item.amount.toFixed(2)})`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total: ${total.toFixed(2)}`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                fontWeight: "bold",
                //padding: "10px",
                textAlign: "left",
                width: "100%",
                color: "dodgerblue",
                fontSize: "1.5rem",
              }}
            >
              Assets
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter((item) => item.category === "Assets")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$${item.amount.toFixed(2)}`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total: ${total.toFixed(2)}`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                fontWeight: "bold",
                //padding: "10px",
                textAlign: "left",
                width: "100%",
                color: "dodgerblue",
                fontSize: "1.5rem",
              }}
            >
              Expenses
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter((item) => item.category === "Expenses")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$(${item.amount.toFixed(2)})`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total: ${total.toFixed(2)}`}</div>
            );
          }}
        />
      </div>
    </div>
  );
};
